import { borderRadius } from 'theme';

export const containerStyles = {
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 100,
};

export const listItemStyles = {
  background: '#FFFFFF',
  padding: '8px',
  borderRadius,
  margin: '16px 0',
};

export const listItemAccordionStyles = {
  PanelContainer: {
    style: {
      margin: '16px 0',
      border: '1px solid #E5E5E5',
      borderRadius: '8px',
    },
  },
  Header: {
    style: {
      borderRadius: '8px',
    },
  },
};

export const skeletonStyles = ({ margin = '' } = {}) => ({
  Root: {
    style: {
      borderRadius,
      margin,
    },
  },
});
