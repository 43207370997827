import { OrganizationIDType } from './OrganizationTypes';

export type FetchPayPeriodType = {
  organizationId: OrganizationIDType;
  pageNumber?: number;
  pageSize?: number;
  from?: string;
  to?: string;
  statuses?: string;
  actionRequired?: boolean;
};

export enum PayPeriodStatusEnum {
  FUTURE = 'FUTURE',
  OPEN = 'OPEN',
  LOCKED = 'LOCKED',
  CLOSED = 'CLOSED',
}

export enum PayPeriodDeductionStatusEnum {
  PARTIALLY_RECOVERED = 'PARTIALLY_RECOVERED',
  FULLY_RECOVERED = 'FULLY_RECOVERED',
}

export enum PayPeriodEwaRequiredActionsEnum {
  CREATE_BATCH = 'CREATE_BATCH',
  INCLUDE_LEDGERS_IN_BATCH = 'INCLUDE_LEDGERS_IN_BATCH',
  SETTLE_LEDGERS_IN_BATCH = 'SETTLE_LEDGERS_IN_BATCH',
}

export enum PayPeriodTpoRequiredActionEnum {
  CREATE_BATCH = 'CREATE_BATCH',
  INCLUDE_LEDGERS_IN_BATCH = 'INCLUDE_LEDGERS_IN_BATCH',
  SETTLE_LEDGERS_IN_BATCH = 'SETTLE_LEDGERS_IN_BATCH',
}

export type PayPeriodType = {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  payGroup: {};
  ewaCutoffTime: string;
  tcoCutoffTime: string;
  officialCutoffTime: string;
  payDate?: string;
  ewaStatus?: PayPeriodStatusEnum;
  tcoStatus?: PayPeriodStatusEnum;
  sentToPayroll: boolean;
  deductionStatus?: PayPeriodDeductionStatusEnum;
  ewaRequiredActions: PayPeriodEwaRequiredActionsEnum[];
  tpoRequiredActions: PayPeriodTpoRequiredActionEnum[];
};

export type PayPeriodStatusFilterType = {
  id: number;
  value: string;
  name: string;
};

export type PayPeriodActionRequiredFilterType = {
  id: number;
  value: boolean;
  name: string;
};
