import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchPayPeriodsWithActions, payPeriodsActionRequiredSelector, payPeriodsListSelector, payPeriodsNumPagesSelector, payPeriodsPageNumberSelector,
  payPeriodsStatusSelector,
} from 'store/slices/dashboard';
import { Grid, ALIGNMENT, Cell } from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import { SIZE } from 'baseui/input';
import { Pagination } from 'baseui/pagination';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { useTranslation } from 'react-i18next';
import { paginationTransparentOverrides } from '../../CommonHelpers';
import PayPeriodItem from './PayPeriodItem/PayPeriodItem';

const PayPeriodSection = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'dashboard']);
  const payPeriods = useAppSelector(payPeriodsListSelector);
  const pageNumber = useAppSelector(payPeriodsPageNumberSelector);
  const numPages = useAppSelector(payPeriodsNumPagesSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const actionRequiredState = useAppSelector(payPeriodsActionRequiredSelector);
  const statusState = useAppSelector(payPeriodsStatusSelector);

  const handlePageChange = ({ nextPage }: { nextPage: number }) => {
    organization && dispatch(fetchPayPeriodsWithActions({
      organizationId: organization.id,
      pageNumber: nextPage,
      pageSize: 10,
      actionRequired: actionRequiredState?.[0]?.value,
      statuses: statusState?.[0]?.value,
    }));
  };

  return (
    <Grid
      align={ALIGNMENT.start}
      gridMargins={[20, 50, 65]}
    >
      <Cell span={12}>
        {payPeriods.map((payPeriod) => (
          <PayPeriodItem key={payPeriod.id} payPeriod={payPeriod} />
        ))}

        {payPeriods?.length > 0 && (
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={pageNumber}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        )}

        {payPeriods?.length === 0 && (
          <Block
            marginTop="16px"
            marginBottom="16px"
          >
            <Card>
              <p>{t('dashboard:dashboard.noResults')}</p>
            </Card>
          </Block>
        )}
      </Cell>
    </Grid>
  );
};

export default PayPeriodSection;
