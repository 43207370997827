export const contentContainerStyles = {
  padding: '0 0 8px 0',
  background: '#E5E5E5',
  marginTop: '24px',
  zIndex: 50,
  minHeight: '100vh',
};

export const containerStyles = {
  display: 'inherit',
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
};

export const imageContainerStyles = {
  width: '100%',
  'text-align': 'center',
  marginBottom: '38px',
};
