import { PayPeriodType } from 'types/PayrollPeriodTypes';
import { Accordion, Panel } from 'baseui/accordion';
import PayPeriodItemHeader from './PayPeriodItemHeader/PayPeriodItemHeader';
import { listItemAccordionStyles } from '../../DashboardHelper';

interface Props {
  payPeriod: PayPeriodType
}

const PayPeriodItem = ({ payPeriod }: Props) => (
  <Accordion
    accordion
    overrides={listItemAccordionStyles}
  >
    <Panel title={<PayPeriodItemHeader payPeriod={payPeriod} />}>
      <p>Expanded</p>
    </Panel>
  </Accordion>
);
export default PayPeriodItem;
