import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useParams,
} from 'react-router-dom';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import { Block } from 'baseui/block';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  employmentPayRatesListSelector,
  editEmploymentPayRates,
  employmentPayRatesPendingSelector,
  employeeDetailsEmployeeTypeChangedSelector,
  fetchEmploymentPayRates,
} from 'store/slices/employees';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import {
  EmployeeDetailsOnDemandPayRatesValuesType,
  EmployeeResponseType,
  EmployeeTypePayRatesType,
  SubElementEWATypeEnum,
  SubElementPayRateTypeEnum,
} from 'types/EmployeeTypes';
import {
  SIZE,
} from 'baseui/input';
import {
  LabelSmall,
} from 'baseui/typography';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCancel,
  faCircleCheck,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import AppInput from 'components/Form/AppInput';
import { Skeleton } from 'baseui/skeleton';
import useIsFormChanged from 'hooks/useIsFormChanged';
import {
  employmentPayRatesMapper,

  saveEmploymentPayRatesDataMapper,
} from 'dataMappers/employmentDataMapper';
import { EmploymentPayRatesSchema } from 'validation/employmentsSchema';
import { useStyletron } from 'baseui';
import { employeeDetailsOnDemandPayRatesInitialValues } from 'initialValues/EmployeeInitialValues';
import PriceFormatter from 'utils/priceFormatter';

export type EmployeeDetailsPayRatesOnDemandPaySectionPropsType = {
  selectedEmployee?: EmployeeResponseType
  employeeType: EmployeeTypePayRatesType
};

export enum EditableFieldType {
  HOURLY = 'HOURLY',
  YEARLY = 'YEARLY',
  PERIOD = 'PERIOD',
  NO_EDIT = 'NO_EDIT',
  HOURS_WORKED = 'HOURS_WORKED',
}

export const EmployeeProfileFormContext = createContext(
  {} as FormikState<EmployeeDetailsOnDemandPayRatesValuesType> & FormikHelpers<EmployeeDetailsOnDemandPayRatesValuesType> & FormikHandlers,
);

const EmployeeDetailsOnDemandPaySectionPayRates = ({ employeeType, selectedEmployee }: EmployeeDetailsPayRatesOnDemandPaySectionPropsType) => {
  const [, theme] = useStyletron();
  const dispatch = useAppDispatch();
  const { employeeID } = useParams<{ organizationID: string, employeeID: string }>();
  const { t } = useTranslation(['errors', 'common', 'locations', 'payGroups', 'employees', 'dateFormats']);
  const [editableField, setEditableField] = useState<EditableFieldType>(EditableFieldType.NO_EDIT);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const pending = useAppSelector(employmentPayRatesPendingSelector);
  const employeeTypeChanged = useAppSelector(employeeDetailsEmployeeTypeChangedSelector);
  const [actionCancel, setActionCancel] = useState(false);
  const confirmModalName = 'CONFIRM_MODAL_EDIT_PAY_RATES';
  const payRatesList = useAppSelector(employmentPayRatesListSelector);
  const modals = useAppSelector(modalsSelector);
  const isConfirmModalEditPayRatesOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL_EDIT_PAY_RATES)?.isOpen;
  const validationSchema = EmploymentPayRatesSchema(employeeType);

  const setIsConfirmModalEditPayRatesOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalEditPayRatesOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL_EDIT_PAY_RATES,
      isOpen,
    }));
  };

  const initialValues = {
    ...employeeDetailsOnDemandPayRatesInitialValues,
    ...(payRatesList && employmentPayRatesMapper({
      payRates: payRatesList.payRatesOverridden,
      contractedHours: payRatesList.contractedHoursOverridden ?? 0,
    })),
  };

  const onSubmit = () => {};

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    setValues,
    isValid,
  } = formik;

  const { isFormChanged, setDefaultValues, changedFields } = useIsFormChanged(values);

  const saveButtonColor = (!isFormChanged || !isValid)
    ? theme.colors.buttonTertiaryDisabledActiveText
    : theme.colors.positive;

  const handleHourlyChanged = () => {
    setEditableField(EditableFieldType.HOURLY);
    setActionCancel(false);
  };

  const handleYearlyChanged = () => {
    setEditableField(EditableFieldType.YEARLY);
    setActionCancel(false);
  };

  const handleCancelClicked = () => {
    setEditableField(EditableFieldType.NO_EDIT);
    setActionCancel(true);
  };

  const handleSaveClicked = () => {
    setEditableField(EditableFieldType.NO_EDIT);
    setIsConfirmModalEditPayRatesOpen(true);
  };

  const handleConfirmOnAction = () => {
    loggedOrganization?.id && selectedEmployee && dispatch(editEmploymentPayRates(({
      organizationID: loggedOrganization.id,
      employmentID: employeeID,
      data: saveEmploymentPayRatesDataMapper({ name: changedFields[0] as SubElementPayRateTypeEnum, values }),
    }))).then(() => {
      dispatch(fetchEmploymentPayRates({ organizationID: loggedOrganization?.id, employeeID }));
    });
  };

  useEffect(() => {
    const combinedValues = {
      ...values,
      ...(employmentPayRatesMapper({
        payRates: payRatesList.payRatesOverridden,
        contractedHours: payRatesList.contractedHoursOverridden ?? 0,
      })),
    };
    setValues(combinedValues);
    setDefaultValues(combinedValues);
  }, [payRatesList,
    actionCancel,
    employeeTypeChanged]);

  return (
    <EmployeeProfileFormContext.Provider value={formik}>
      <form onSubmit={handleSubmit}>
        {pending ? (
          <Skeleton
            height="72px"
            animation
          />
        ) : (
          <Grid
            gridColumns={12}
            align={ALIGNMENT.center}
          >
            {employeeType[0].value !== SubElementEWATypeEnum.HOURLY_PAID && (
            <AppInput
              name="contractedHours"
              placeholder="0"
              inputProps={{
                autoComplete: 'off',
                disabled: true,
              }}
              cellSpan={[12, 6, 2.4]}
              label={t('employees:paySettings.standardHoursWorked.label')}
              context={EmployeeProfileFormContext}
            />
            )}
            {employeeType[0].value === SubElementEWATypeEnum.SALARIED_NOT_CLOCKING && (
            <AppInput
              name="YEARLY"
              placeholder="0"
              inputProps={{
                startEnhancer: '$',
                autoComplete: 'off',
                disabled: editableField !== EditableFieldType.YEARLY || employeeTypeChanged,
                value: editableField === EditableFieldType.YEARLY ? values.YEARLY : PriceFormatter('en-US', 'USD', 'decimal').format(values.YEARLY),
              }}
              cellSpan={[12, 6, 2.4]}
              label={(
                <Block
                  display="flex"
                  alignItems="center"
                >
                  <LabelSmall>{t('employees:paySettings.annualPayRate.label')}</LabelSmall>
                  {employeeType[0].value === SubElementEWATypeEnum.SALARIED_NOT_CLOCKING && (
                  <Button
                    type="button"
                    onClick={handleYearlyChanged}
                    size="compact"
                    kind={KIND.tertiary}
                    disabled={editableField !== EditableFieldType.NO_EDIT || employeeTypeChanged}
                    overrides={{
                      Root: {
                        style: {
                          paddingTop: '3px',
                          paddingBottom: '3px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        },
                      },
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPencil}
                    />
                  </Button>
                  )}
                </Block>
                )}
              context={EmployeeProfileFormContext}
            />
            )}

            {employeeType[0].value !== SubElementEWATypeEnum.HOURLY_PAID && (
            <>
              <AppInput
                name="HOURLY"
                placeholder="0"
                inputProps={{
                  startEnhancer: '$',
                  autoComplete: 'off',
                  value: editableField === EditableFieldType.HOURLY ? values.HOURLY : PriceFormatter('en-US', 'USD', 'decimal').format(values.HOURLY),
                  disabled: editableField !== EditableFieldType.HOURLY || employeeTypeChanged,
                }}
                cellSpan={[12, 6, 2.4]}
                label={(
                  <Block
                    display="flex"
                    alignItems="center"
                  >
                    <LabelSmall>{t('employees:paySettings.hourlyPayRate.label')}</LabelSmall>
                    <Button
                      type="button"
                      onClick={handleHourlyChanged}
                      size="compact"
                      kind={KIND.tertiary}
                      disabled={editableField !== EditableFieldType.NO_EDIT || employeeTypeChanged}
                      overrides={{
                        Root: {
                          style: {
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          },
                        },
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                      />
                    </Button>
                  </Block>
                  )}
                context={EmployeeProfileFormContext}
              />

              <AppInput
                name="PERIOD"
                placeholder="0"
                inputProps={{
                  startEnhancer: '$',
                  autoComplete: 'off',
                  disabled: true,
                  value: PriceFormatter('en-US', 'USD', 'decimal').format(values.PERIOD),
                }}
                cellSpan={[12, 6, 2.4]}
                label={t('employees:paySettings.periodPayRate.label')}
                context={EmployeeProfileFormContext}
              />
            </>
            )}

            {editableField !== EditableFieldType.NO_EDIT && !employeeTypeChanged && (
            <Cell
              span={[12, 4, 2.4]}
              align={ALIGNMENT.center}
              overrides={{
                Cell: {
                  style: {
                    marginTop: '16px',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginBottom: '0px',
                  },
                },
              }}
            >
              <Button
                type="button"
                onClick={handleSaveClicked}
                size={SIZE.large}
                kind={KIND.tertiary}
                disabled={!isFormChanged || !isValid}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faCircleCheck}
                  color={saveButtonColor}
                />
              </Button>
              <Button
                type="button"
                onClick={handleCancelClicked}
                size={SIZE.large}
                kind={KIND.tertiary}
              >
                <FontAwesomeIcon
                  icon={faCancel}
                  size="lg"
                />
              </Button>
            </Cell>
            )}

          </Grid>
        )}
        <ConfirmModal
          onAction={handleConfirmOnAction}
          title={t('employees:employeeDetails.paySettings.confirm.title')}
          actionBtnText={t('common:confirm.ok')}
          onClose={() => { setIsConfirmModalEditPayRatesOpen(false); setActionCancel(true); }}
          modalNameSpecified={confirmModalName}
        />
      </form>
    </EmployeeProfileFormContext.Provider>
  );
};

export default memo(EmployeeDetailsOnDemandPaySectionPayRates);
