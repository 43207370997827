import { ParagraphLarge, ParagraphSmall } from 'baseui/typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PayPeriodType } from 'types/PayrollPeriodTypes';

interface Props {
  payPeriod: PayPeriodType
}

const PayPeriodItemHeader = ({ payPeriod }: Props) => {
  const { t } = useTranslation(['dateFormats']);
  const datePickerFormat = t('dateFormats:standard');

  const startDate = moment(payPeriod.startDate).format(datePickerFormat);
  const endDate = moment(payPeriod.endDate).format(datePickerFormat);

  return (
    <div>
      <ParagraphLarge margin={0} style={{ fontWeight: '600' }}>
        {payPeriod.name}
      </ParagraphLarge>
      <ParagraphSmall margin="8px 0 0">
        {startDate}
        {' '}
        -
        {endDate}
      </ParagraphSmall>
    </div>
  );
};

export default PayPeriodItemHeader;
