import {
  createContext,
  memo,
  useEffect,
  useState,
} from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  Button,
  KIND,
} from 'baseui/button';
import {
  Grid,
  Cell,
  ALIGNMENT,
} from 'baseui/layout-grid';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { Block } from 'baseui/block';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import { employeeValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  editEmployeeDetailsOnDemandPay,
  employeeConfigsSelector,
  employeeDetailsPendingSelector,
  fetchEmployeesOffers,
  employeeDetailsSelector,
  fetchEmployeesTPOOffers,
  fetchEmploymentPayRates,
  employmentPayRatesPendingSelector,
  employeeDetailsEmployeeTypeChangedSelector,
  setEmployeeTypeChanged,
} from 'store/slices/employees';
import {
  loggedOrganizationSelector,
} from 'store/slices/loggedOrganization';
import {
  ConfigType,
} from 'types/OrganizationTypes';
import {
  organizationConfigMapper,
} from 'dataMappers/organizationsDataMappers';
import {
  saveEmployeesConfigsMapper,
  saveEmployeeDetailsOnDemandPayMapper,
  employeeDetailsOnDemandPayMapper,
} from 'dataMappers/employmentDataMapper';
import AppInput from 'components/Form/AppInput';
import Loader from 'components/Loader';
import { errorSelector } from 'store/slices/error';
import { resetEmployeeDetailsWorkersDrawsAndOffers } from 'store/events';
import { notificationSelector } from 'store/slices/notification';
import {
  EmployeeDetailsOnDemandPayValuesType,
  EmployeeResponseType,
  SubElementEWATypeEnum,
} from 'types/EmployeeTypes';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import hasAccess from 'utils/hasAccess';
import useIsFormChanged from 'hooks/useIsFormChanged';
import {
  SIZE,
} from 'baseui/input';
import {
  defaultConfigSelector,
  organizationConfigSelector,
} from 'store/slices/organizations';
import {
  LabelMedium,
  LabelSmall,
} from 'baseui/typography';
import { Tag, VARIANT } from 'baseui/tag';
import moment from 'moment';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import { employeeDetailsOnDemandPayInitialValues } from 'initialValues/EmployeeInitialValues';
import { emptyPlaceholder } from 'theme';
import AppSelect from 'components/Form/AppSelect';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import {
  profileContainerStyles,
  showOffersBtnOverrides,
} from '../../EmployeesHelpers';
import EmployeeDetailsOnDemandPaySectionPayRates from './PayRates/EmployeeDetailsOnDemandPaySectionPayRates';

export const EmployeeProfileFormContext = createContext(
  {} as FormikState<EmployeeDetailsOnDemandPayValuesType> & FormikHelpers<EmployeeDetailsOnDemandPayValuesType> & FormikHandlers,
);

export type EmployeeDetailsProfileSectionPropsType = {
  selectedEmployee?: EmployeeResponseType
  expanded?: boolean
};

const EmployeeDetailsOnDemandPaySection = ({
  selectedEmployee,
  expanded,
}: EmployeeDetailsProfileSectionPropsType) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { employeeID } = useParams<{ organizationID: string, employeeID: string }>();
  const { t } = useTranslation(['errors', 'common', 'locations', 'payGroups', 'employees', 'dateFormats']);
  const [css] = useStyletron();
  const theme = useStyletron()[1];
  const [isExpanded, setIsExpanded] = useState(false);
  const employeeTypeChanged = useAppSelector(employeeDetailsEmployeeTypeChangedSelector);
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const employeeConfigs = useAppSelector(employeeConfigsSelector);
  const pending = useAppSelector(employeeDetailsPendingSelector);
  const pendingPayRates = useAppSelector(employmentPayRatesPendingSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const error = useAppSelector(errorSelector);
  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const organizationOfferPercentPerEmployee = Number(organizationConfig?.find(
    (item) => (
      item.configKeyName === ConfigType.OFFER_PERCENT
    ),
  )?.configValue)
    ?? defaultConfig?.OFFER_PERCENT;
  const organizationTPOOfferPercentPerEmployee = Number(organizationConfig?.find(
    (item) => (
      item.configKeyName === ConfigType.TPO_PERCENT
    ),
  )?.configValue)
    ?? defaultConfig?.TPO_PERCENT;
  const initialValues = {
    ...employeeDetailsOnDemandPayInitialValues,
    ...(employeeConfigs && organizationConfigMapper(employeeConfigs)),
  };

  const employeeTypeOptionsHourlyPaid = [
    {
      value: 'HOURLY_PAID',
      label: t('employees:employeeDetails.employeeType.HOURLY_PAID'),
    },
  ];

  const employeeTypeOptionsRegularlyPaid = [
    {
      value: 'REGULARLY_PAID_HOURLY',
      label: t('employees:employeeDetails.employeeType.REGULARLY_PAID_HOURLY'),
    },
  ];

  const employeeTypeOptionsSalaried = [
    {
      value: 'SALARIED_NOT_CLOCKING',
      label: t('employees:employeeDetails.employeeType.SALARIED_NOT_CLOCKING'),
    },
  ];

  const supportedOrgEmploymentEWATypes = organizationConfig?.find((item) => item.configKeyName === ConfigType.SUPPORTED_EMPLOYMENT_EWA_TYPES)?.configValue
  || defaultConfig?.SUPPORTED_EMPLOYMENT_EWA_TYPES;

  const [employeeTypeOptions, setEmployeeTypeOptions] = useState(employeeTypeOptionsSalaried);
  const mainProgram = employeeDetails && employeeDetails.programs.filter((i) => (i === 'TCO' || i === 'EWA'));

  const modals = useAppSelector(modalsSelector);
  const isConfirmModalOpen = !!modals?.find((item) => item.name === ModalNames.CONFIRM_MODAL)?.isOpen;
  const setIsConfirmModalOpen = (
    isOpen: boolean,
  ) => {
    isConfirmModalOpen !== isOpen && dispatch(setModal({
      name: ModalNames.CONFIRM_MODAL,
      isOpen,
    }));
  };

  const onSubmit = (
    values: EmployeeDetailsOnDemandPayValuesType,
  ) => {
    if (employeeTypeChanged) { setIsConfirmModalOpen(true); }
    (!employeeTypeChanged && loggedOrganization?.id && employeeID && selectedEmployee) && dispatch(editEmployeeDetailsOnDemandPay({
      employeeID,
      organization: loggedOrganization,
      employeeDetailsData: saveEmployeeDetailsOnDemandPayMapper(values, selectedEmployee),
      employeeConfigsData: saveEmployeesConfigsMapper(values),
    }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    initialTouched: {
      offerPercentPerEmployee: true,
      tpoOfferPercentPerEmployee: true,
    },
  });

  const handleShowTPOOffer = () => {
    dispatch(fetchEmployeesTPOOffers({ organizationID: loggedOrganization?.id, employeeID }));
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_TPO_OFFER_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const handleShowEWAOffer = () => {
    dispatch(fetchEmployeesOffers({ organizationID: loggedOrganization?.id, employeeID }));
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_OFFER_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const handleClickCancel = () => {
    dispatch(resetEmployeeDetailsWorkersDrawsAndOffers());
    history.push('/loggedOrganization/employees');
  };

  const {
    values,
    errors,
    setValues,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setErrors,
    isValid,
  } = formik;

  const { isFormChanged, setDefaultValues, defaultData } = useIsFormChanged(values);

  const handleOnCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setSubmitting(false);
  };

  useEffect(() => {
    if (selectedEmployee?.id) {
      const combinedValues = {
        ...values,
        ...employeeDetailsOnDemandPayMapper(selectedEmployee, t),
        offerPercentPerEmployee: employeeConfigs?.find((item) => item.configKeyName === 'OFFER_PERCENT')?.configValue
          || organizationOfferPercentPerEmployee || 0,
        tpoOfferPercentPerEmployee: employeeConfigs?.find((item) => item.configKeyName === 'TIP_CASH_OUT_PERCENT')?.configValue
          || organizationTPOOfferPercentPerEmployee || 0,
      };
      setValues(combinedValues);
      setDefaultValues(combinedValues);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    const combinedValues = {
      ...values,
      ...(employeeConfigs && organizationConfigMapper(employeeConfigs)),
    };
    setValues(combinedValues);
    setDefaultValues(combinedValues);
  }, [employeeConfigs]);

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    const checkEWAOfferPercent = typeof organizationOfferPercentPerEmployee === 'number'
    && typeof values.offerPercentPerEmployee === 'number'
    && values?.offerPercentPerEmployee
    && values.offerPercentPerEmployee > organizationOfferPercentPerEmployee;
    const checkTPOOfferPercent = typeof organizationTPOOfferPercentPerEmployee === 'number'
    && typeof values.tpoOfferPercentPerEmployee === 'number'
    && values?.tpoOfferPercentPerEmployee
    && values.tpoOfferPercentPerEmployee > organizationTPOOfferPercentPerEmployee;

    if (checkEWAOfferPercent || checkTPOOfferPercent) {
      setErrors({
        ...errors,
        offerPercentPerEmployee: checkEWAOfferPercent ? t('errors:workerOfferPercentTooHigh', {
          PERCENT: organizationOfferPercentPerEmployee,
        }) : undefined,
        tpoOfferPercentPerEmployee: checkTPOOfferPercent ? t('errors:workerOfferPercentTooHigh', {
          PERCENT: organizationTPOOfferPercentPerEmployee,
        }) : undefined,
      });
    }

    setEmployeeTypeOptions([
      ...(supportedOrgEmploymentEWATypes?.includes(SubElementEWATypeEnum.HOURLY_PAID) ? employeeTypeOptionsHourlyPaid : []),
      ...(supportedOrgEmploymentEWATypes?.includes(SubElementEWATypeEnum.REGULARLY_PAID_HOURLY) ? employeeTypeOptionsRegularlyPaid : []),
      ...(supportedOrgEmploymentEWATypes?.includes(SubElementEWATypeEnum.SALARIED_NOT_CLOCKING) ? employeeTypeOptionsSalaried : []),
    ]);
  }, [values, errors]);

  const handleOnExpand = () => {
    setIsExpanded(true);
    employeeDetails?.id
      && dispatch(fetchEmploymentPayRates({ organizationID: loggedOrganization?.id, employeeID: selectedEmployee?.id.toString() }))
        .then(() => setIsExpanded(false));
  };

  useEffect(() => {
    employeeDetails?.id && isExpanded
      && dispatch(fetchEmploymentPayRates({ organizationID: loggedOrganization?.id, employeeID: selectedEmployee?.id.toString() }));
  }, [employeeDetails?.id]);

  useEffect(() => {
    if (isFormChanged && (values.employeeType[0].value !== JSON.parse(defaultData).employeeType[0].value)) {
      dispatch(setEmployeeTypeChanged(true));
    } else {
      dispatch(setEmployeeTypeChanged(false));
    }
  }, [isFormChanged]);

  return (
    <EmployeeProfileFormContext.Provider value={formik}>
      <AccordionSection
        title={t('employees:onDemandPay.section.header')}
        disabled={false}
        showStatus={false}
        expanded={expanded}
        onExpand={handleOnExpand}
      >
        <div className={css(profileContainerStyles)}>
          <Loader active={pendingPayRates || pending} />

          <form onSubmit={handleSubmit}>
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={12}
                align={ALIGNMENT.start}
              >
                <LabelMedium>{t('employees:service.header')}</LabelMedium>
              </Cell>
            </Grid>
            <br />

            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={[12, 4, 2]}
              >
                <LabelSmall display="inline-flex">
                  {t('employees:onDemandPay.service.status.label')}
                  : &nbsp;
                </LabelSmall>
                <Tag
                  closeable={false}
                  variant={VARIANT.solid}
                  overrides={{
                    Root: {
                      style: {
                        backgroundColor: employeeDetails?.workerId
                          ? theme.colors.backgroundPositiveLight : theme.colors.backgroundWarningLight,
                      },
                    },
                    Text: {
                      style: {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                >
                  <LabelSmall
                    color={employeeDetails?.workerId ? '#03582F' : '#674D1B'}
                    width="fit-content"
                  >
                    {employeeDetails?.workerId
                      ? t('employees:enrolled')
                      : t('employees:notEnrolled')}
                  </LabelSmall>
                </Tag>
              </Cell>

              <Cell
                span={[12, 4, 2]}
              >
                <LabelSmall display="inline-flex">
                  {t('employees:onDemandPay.service.type.label')}
                  : &nbsp;
                </LabelSmall>
                <Tag
                  closeable={false}
                  variant={VARIANT.solid}
                  overrides={{
                    Root: {
                      style: {
                        backgroundColor: theme.colors.backgroundAccentLight,
                      },
                    },
                    Text: {
                      style: {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                >
                  <LabelSmall color="#1661ED" width="fit-content">
                    {mainProgram && mainProgram.length > 0
                      ? t(`employees:employeeDetails.program.${mainProgram}`)
                      : emptyPlaceholder}
                  </LabelSmall>
                </Tag>
              </Cell>

              <Cell
                span={[12, 4, 3]}
              >
                <LabelSmall display="inline-flex">
                  {t('employees:onDemandPay.service.enrollmentDate.label')}
                  : &nbsp;
                </LabelSmall>
                <Tag
                  closeable={false}
                  variant={VARIANT.solid}
                  overrides={{
                    Root: {
                      style: {
                        backgroundColor: theme.colors.backgroundTertiary,
                      },
                    },
                    Text: {
                      style: {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                >
                  <LabelSmall color={theme.colors.contentSecondary} width="fit-content">
                    {moment(employeeDetails?.enrollmentDate).format(t('dateFormats:standard'))}
                  </LabelSmall>
                </Tag>
              </Cell>
            </Grid>

            <br />
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={12}
                align={ALIGNMENT.start}
                overrides={{
                  Cell: {
                    style: {
                      paddingBottom: '8px',
                    },
                  },
                }}
              >
                <LabelMedium>{t('employees:paySettings.header')}</LabelMedium>
              </Cell>
            </Grid>
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <AppSelect
                name="employeeType"
                label={t('employees:onDemandPay.service.employeeType.label')}
                options={employeeTypeOptions}
                cellSpan={[12, 4, 2.4]}
                context={EmployeeProfileFormContext}
                selectProps={{
                  clearable: false,
                }}
              />
            </Grid>
            {values.employeeType[0].value !== SubElementEWATypeEnum.HOURLY_PAID && (
              <EmployeeDetailsOnDemandPaySectionPayRates employeeType={values.employeeType} selectedEmployee={selectedEmployee} />
            )}
            <br />
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={12}
                align={ALIGNMENT.start}
                overrides={{
                  Cell: {
                    style: {
                      paddingBottom: '8px',
                    },
                  },
                }}
              >
                <LabelMedium>{t('employees:EWAsettings.header')}</LabelMedium>
              </Cell>
            </Grid>
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <AppInput
                showStar
                name="offerPercentPerEmployee"
                label={t('employees:EWAOfferPercent')}
                cellSpan={[12, 4, 2.4]}
                context={EmployeeProfileFormContext}
                inputProps={{
                  endEnhancer: '%',
                  autoComplete: 'off',
                  min: 0,
                  max: organizationOfferPercentPerEmployee || 100,
                  type: 'number',
                  disabled: !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager]),
                }}
              />
              <AppInput
                showStar
                name="lowerEarningsThreshold"
                label={t('employees:lowerEarningsThreshold')}
                cellSpan={[12, 4, 2.4]}
                context={EmployeeProfileFormContext}
                inputProps={{
                  min: 0,
                  max: 999999.99,
                  type: 'number',
                  step: 'any' as any,
                  endEnhancer: selectedEmployee?.lowerEarningsThreshold?.currencyCode,
                  autoComplete: 'off',
                }}
              />
              <Cell span={[12, 4, 2]}>
                <Button
                  size={SIZE.compact}
                  overrides={showOffersBtnOverrides}
                  type="button"
                  kind={KIND.secondary}
                  onClick={handleShowEWAOffer}
                >
                  {t('employees:viewEWAOffer.button')}
                </Button>
              </Cell>
            </Grid>

            <br />
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={12}
                align={ALIGNMENT.start}
                overrides={{
                  Cell: {
                    style: {
                      paddingBottom: '8px',
                    },
                  },
                }}
              >
                <LabelMedium>{t('employees:TPOsettings.header')}</LabelMedium>
              </Cell>
            </Grid>

            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <AppInput
                showStar
                name="tpoOfferPercentPerEmployee"
                label={t('employees:TPOOfferPercent')}
                cellSpan={[12, 4, 2.4]}
                context={EmployeeProfileFormContext}
                inputProps={{
                  endEnhancer: '%',
                  autoComplete: 'off',
                  min: 0,
                  max: organizationTPOOfferPercentPerEmployee || 100,
                  type: 'number',
                  disabled: !hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager, AccessUnit.EWAClientManager]),
                }}
              />
              <Cell span={[12, 4, 2]}>
                <Button
                  size={SIZE.compact}
                  overrides={showOffersBtnOverrides}
                  type="button"
                  kind={KIND.secondary}
                  onClick={handleShowTPOOffer}
                >
                  {t('employees:viewTPOOffer.button')}
                </Button>
              </Cell>
            </Grid>

            <br />
            <hr />
            <Block
              marginTop="24px"
            >
              <Grid
                gridColumns={12}
                align={ALIGNMENT.end}
              >
                <Cell
                  span={9}
                  align={ALIGNMENT.end}
                />
                <Cell
                  span={3}
                  align={ALIGNMENT.end}
                >
                  <Block
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Block
                      display="inline-flex"
                      marginRight="16px"
                    >
                      <Button
                        type="button"
                        kind={KIND.secondary}
                        onClick={handleClickCancel}
                        overrides={{
                          BaseButton: {
                            props: {
                              id: 'EmployeeDetailsOnDemandPaySection-cancel',
                            },
                          },
                        }}
                      >
                        {t('common:cancel')}
                      </Button>
                    </Block>
                    <Button
                      type="submit"
                      kind={KIND.primary}
                      disabled={isSubmitting || !isFormChanged || !isValid}
                      overrides={{
                        Root: {
                          props: {
                            id: 'EmployeeDetailsOnDemandPaySection-save',
                          },
                        },
                      }}
                    >
                      {t('common:save')}
                    </Button>
                  </Block>
                </Cell>
              </Grid>
            </Block>
          </form>
        </div>
      </AccordionSection>
      <ConfirmModal
        onAction={() => {
          (loggedOrganization?.id && employeeID && selectedEmployee) && dispatch(editEmployeeDetailsOnDemandPay({
            employeeID,
            organization: loggedOrganization,
            employeeDetailsData: saveEmployeeDetailsOnDemandPayMapper(values, selectedEmployee),
            employeeConfigsData: saveEmployeesConfigsMapper(values),
          }));
        }}
        title={t('employees:employeeDetails.employeeType.confirmation.title')}
        actionBtnText={t('common:save')}
        onClose={handleOnCloseConfirmModal}
      >
        {`${t(`employees:employeeDetails.employeeType.${values.employeeType[0].value}.confirmation.body`)}`}
      </ConfirmModal>
    </EmployeeProfileFormContext.Provider>
  );
};

export default memo(EmployeeDetailsOnDemandPaySection);
